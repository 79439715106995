.AppHeader {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  top: 20px;
  width: 100%;
  padding: 0 20px;
}

.AppHeader a {
  margin-right: 40px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
}

.AppHeader img {
  margin-right: 24px;
}
