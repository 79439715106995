@font-face {
  font-family: 'Basis';
  src: url('/public/font/basis33.woff2') format('woff2'),
          url('/public/font/basis33.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Basis, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:visited {
  color: #ffffff;
}

a:hover {
  color: #CFEA2B;
}


* {
  box-sizing: border-box;
}
