
.player-container {
  position: relative;
}

.player-container .PlayButton {
  position: absolute;
  top: 29.5%;
  left: 46.3%;
}

.player-container .CurrentSong {
  position: absolute;
  top: 57.5%;
  left: 14%;
}

.player-container .VolumeSlider {
  position: absolute;
  top: 3.3%;
  left: 31.6%;
}
