.VolumeSlider {
  width: 154px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 0 3px;
  height: 19px;
}

.VolumeSlider-input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  outline: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.VolumeSlider-bg {
  position: relative;
  z-index: 0;
  margin-top: 2px;
  height: 13px;
  width: 100%;
  background-image: url('/public/img/volume_bg.png');
  background-repeat: repeat-x;
  background-size: 4px 13px;
}

.VolumeSlider:hover .VolumeSlider-bg {
  background-image: url('/public/img/volume_bg_hover.png');
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
.VolumeSlider-input::-webkit-slider-runnable-track {
  background-color: transparent;
  height: 20px;
}

/* slider thumb */
.VolumeSlider-input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  background-color: transparent;
  height: 0rem;
  width: 0rem;
}

.VolumeSlider-input::-moz-range-track {
  background-color: transparent;
  height: 20px;
}

/* slider thumb */
.VolumeSlider-input::-moz-range-thumb {
  background-color: transparent;
  border: none; /*Removes extra border that FF applies*/
  height: 0rem;
  width: 0rem;
}