.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('/public/img/pattern.png');
  background-repeat: repeat;
  background-size: auto;
}

.App-gradient {
  background: linear-gradient(360deg, #0D0D0D 25.85%, rgba(0, 0, 0, 0) 100%);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header h2 {
  margin-top: 0;
}

.player-container {
  position: relative;
}

.player-container .PlayButton {
  position: absolute;
  top: 29.5%;
  left: 46.3%;
}

.player-container .CurrentSong {
  position: absolute;
  top: 57.5%;
  left: 14%;
}

.player-container .VolumeSlider {
  position: absolute;
  top: 3.3%;
  left: 31.6%;
}
