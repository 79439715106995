
.PlayButton {
  cursor: pointer;
}

.PlayButton path {
  transition: fill 0.2s linear;
}

.PlayButton:hover path,
.PlayButton:hover rect {
  fill: #CFEA2B;
}

.CurrentSong {
  width: 314px;
  height: 40px;
  overflow: hidden;
}

.CurrentSong-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.CurrentSong .CurrentSong-text {
  font-size: 20px;
  height: 40px;
  line-height: 42px;
  flex-shrink: 0;
  margin: 0;
  padding: 0 15px;
  min-width: 100%;
  white-space: nowrap;

  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 10s;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}