
.AboutContainer {
  word-wrap: break-word;
  width: 640px;
  font-size: 20px;
  text-transform: uppercase;
}

.AboutSection {
  display: inline-block;
  border: 1px solid #fff;
  padding: 13px 18px;
  margin-top: -1px;
  margin-left: -1px;
  cursor: pointer;
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }

  .shape {
    position: absolute;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    transform-origin: center center;
    transition: visibility 0.3s linear, opacity 0.3s ease-out;

    p {
      font-size: 16px;
      line-height: 18px;
      margin: 0;
    }
  }

  span:hover {
    z-index: 3;
    color: #CFEA2B;

    +.shape {
      visibility: visible;
      opacity: 1;
    }
  }
}

#shape1 {
  background-image: url('~/public/img/about_shape_1.svg');
  top: 0;
  left: 0;
  width: 529px;
  height: 232px;
  color: #9B0B59;

  .text1 {
    width: 400px;
    margin-left: 35px;
    margin-top: 75px;
  }

  .text2 {
    width: 275px;
    margin-left: 160px;
  }
}

#shape2 {
  background-image: url('~/public/img/about_shape_2.svg');
  top: 9px;
  left: -278px;
  width: 834px;
  height: 210px;
  color: #12666B;

  .text1 {
    width: 250px;
    margin: 47px 0 0 310px;
  }
}

#shape3 {
  background-image: url('~/public/img/about_shape_3.svg');
  top: -121px;
  left: 42px;
  width: 667px;
  height: 307px;
  color: #855914;

  .text1 {
    width: 230px;
    margin: 28px 0 0 210px;
  }

  .text2 {
    width: 230px;
    margin: 32px 0 0 416px;
  }

  .text3 {
    width: 230px;
    margin: -6px 0 0 32px;
  }

  .text4 {
    width: 220px;
    margin: -10px 0 0 295px;
  }
}

#shape4 {
  background-image: url('~/public/img/about_shape_4.svg');
  top: -84px;
  left: -341px;
  width: 498px;
  height: 205px;
  color: #009728;

  .text1 {
    margin: 20px 0 0 50px;
    transform: rotate(1.77deg);
  }

  .text2 {
    margin: 24px 0 0 50px;
    transform: rotate(-7deg);
  }

  .text3 {
    margin: 26px 0 0 180px;
  }

  .text4 {
    margin: 37px 0 0 85px;
    transform: rotate(-4.25deg);
  }
}

#shape6 {
  background-image: url('~/public/img/about_shape_6.svg');
  top: -155px;
  left: -275px;
  width: 522px;
  height: 253px;
  color: #3D10BF;

  .text1 {
    margin: 40px 0 0 118px;
  }

  .text2 {
    margin: 2px 0 0 100px;
  }

  .text3 {
    margin: 2px 0 0 40px;
  }

  .text4 {
    width: 230px;
    margin: 2px 0 0 50px;
  }
}